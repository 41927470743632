.post {
}

.image {
  width: 100%;
  aspect-ratio: '1:1';
}

.author {
  margin-top: var(--space-xs);

  color: var(--color-dark-gray);
}
