.instagram {
  --gap: var(--space-l);
}

.item {
  width: 22.547vw;
  min-width: 300px;
  max-width: 423px;

  vertical-align: top;
}

.post {
  cursor: pointer;
}

.track {
  margin-top: var(--space-l);
}

.gallery {
  margin-right: calc(var(--gap) / 2 * -1);
  margin-left: calc(var(--gap) / 2 * -1);
}

.item {
  padding-right: calc(var(--gap) / 2);
  padding-left: calc(var(--gap) / 2);
}

@media (--desktop-m) {
  .instagram {
    --gap: var(--space-n);
  }
}

@media (--desktop) {
  .item {
    width: 300px;
  }
}

@media (--mobile-m) {
  .item {
    width: 265px;
    min-width: 265px;
  }
}

@media (--mobile) {
  .item {
    width: 225px;
    min-width: 225px;
  }
}
