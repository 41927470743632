.section {
  overflow: hidden;
}

.galleryItem {
  vertical-align: bottom;
}

.tabs {
  margin-top: var(--space-n);
}

.arrows {
  opacity: 0;

  pointer-events: none;

  &.visible {
    opacity: 1;

    pointer-events: all;
  }
}

.text {
  composes: averageText from 'containers';

  font-size: var(--size-m);
  line-height: 1.5;
}

.wrapperGallery,
.wrapperProgress {
  composes: main from 'containers';

  margin-top: var(--space-l);
}

.wrapperProgress {
  padding: 0;
}

.wrapperGallery {
  overflow: hidden;
}

@media (--desktop) {
  .text {
    font-size: var(--size-n);
  }
}
