.section {
  overflow: hidden;
}

.wrapperGallery {
  composes: main from 'containers';
  composes: cropped from 'containers';
}

.gallery {
  margin-top: var(--space-l);
}

.footerText {
  composes: main from 'containers';

  margin-top: var(--space-n);

  color: var(--color-dark-gray);
}

.footerPhoneLink {
  color: var(--color-dark-gray);
}

@media (--mobile) {
  .navArrows {
    display: flex;
    justify-content: flex-end;
  }
}
