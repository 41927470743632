.promoPlaceholder {
  position: relative;

  width: 100%;

  &:before {
    display: block;
    padding-bottom: 100%;

    content: '';
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--color-light-gray);
  border-radius: 50%;
}

.title {
  max-width: 290px;

  font-weight: 500;
  font-size: var(--size-xxl);
  font-family: var(--font-hero);
  line-height: 150%;
  text-align: center;
}

.text {
  max-width: 290px;
  margin-top: var(--space-n);

  font-size: var(--size-n);
  line-height: 150%;
  text-align: center;
}

@media (--desktop-m) {
  .title {
    font-size: var(--size-xl);
  }
}

@media (--mobile-m) {
  .title {
    font-size: var(--size-xm);
  }

  .text {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
  .text {
    max-width: 200px;
  }
}
