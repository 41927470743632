.card {
  position: relative;

  &.promo {
    .actualPrice {
      color: var(--color-red);
    }
  }
}

.imgContainer {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  background-color: var(--color-light-gray);
  border-radius: 5px;
}

.image {
  aspect-ratio: '1:1';
}

.cnImage {
  object-fit: contain;
}

.actions {
  position: absolute;
  top: var(--space-s);
  right: var(--space-s);
  z-index: 2;
}

.name {
  display: inline-block;
  max-width: 100%;

  overflow: hidden;

  color: var(--color-black);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price {
  display: grid;
  grid-auto-flow: column;
  align-items: baseline;
}

.actualPrice {
  font-weight: 500;
  font-size: var(--size-m);
  line-height: 1.5;
}

.expiredPrice {
  margin-left: var(--space-xs);
}

.discount {
  align-self: center;
  margin-left: var(--space-xs);

  font-size: 13px;
}

.info {
  margin-top: var(--space-s);
}

@media (--desktop-m) {
  .uzbDiscount {
    display: none;
  }
}

@media (--mobile-m) {
  .expiredPrice {
    margin-left: var(--space-xxs);
  }
  .actions {
    top: var(--space-xs);
    right: var(--space-xs);
  }
  .actualPrice {
    font-size: var(--size-n);
  }
  .expiredPrice {
    font-size: var(--size-s);
  }
  .discount {
    display: none;
  }
}
