/* TODO: присутствует закомментированный код, относящийся к Instagram/Facebook */

.instagramDescription {
  width: 100%;

  font-size: var(--size-m);
  line-height: 1.5;
  /* max-width: 88%; */
}

@media (--desktop-m) {
  .instagramDescription {
    /* max-width: 80%; */
  }
}

@media (--desktop) {
  .instagramDescription {
    max-width: unset;

    font-size: var(--size-n);
    line-height: 1.5;
  }
}
